// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-jsx": () => import("/opt/build/repo/web/src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/web/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-archive-jsx": () => import("/opt/build/repo/web/src/pages/archive.jsx" /* webpackChunkName: "component---src-pages-archive-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/web/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

